


































































































































































































































































import { Component, Prop, Vue, Ref } from "vue-property-decorator";

export default {
  data() {
    return {
      tem: require("@/assets/product/img/num2.png"),
      time: require("@/assets/product/img/time2.png"),
      toggle: 1,
    };
  },
};
